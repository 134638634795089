@use "./common_variables" as *;

.container-login {
    width: 100%;
    height: inherit !important;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-grow: 1;
    padding-top: none !important;
}

.div-logo-login {
    position: absolute;
    top: 6.8%;
    left: 6.4%;
    width: 23.5% !important;
    max-width: 230px;
}

.text-image-login {
    position: absolute;
    text-wrap: pretty;
    text-align: start;
    bottom: 0;
}

.div-login-form {
    padding-left: 0.9rem;
    padding-right: 0.9rem;
    align-self: center;
    justify-self: center;
    padding-bottom: 48px;
}

.loader {
    line-height: 1;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background: rgb(239, 241, 246) no-repeat;
    background-color: rgb(239, 241, 246);
    line-height: 1;
    overflow: hidden;
    position: relative;
}
