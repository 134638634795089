@use "./app/multi-tenant-web-portal/shared/styles" as *;
@use "./app/multi-tenant-web-portal/shared/styles/colors" as *;
@import url("https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@400&display=swap");
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../node_modules/bootstrap-icons/font/bootstrap-icons.min.css";

* {
    font-family: "Lexend Deca", sans-serif !important;
    overflow-x: hidden;
    overflow-y: auto !important;
    font-size: 16px;
    scrollbar-width: thin;
    scrollbar-color: $secondary-color transparent !important;
    &::-webkit-scrollbar {
        width: 5px;
        height: 8px;
        background-color: #ffffff;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $secondary-color !important;
    }
}

.wrapper {
    width: calc(100vw - 100px);
}

html,
body {
    min-height: 100vh !important;
    height: 100vh !important;
}

i {
    color: $primary-color !important;
}

@media (max-width: 979px) {
    body {
        padding-top: 0px;
    }
}

input::placeholder {
    color: $neutral-level-4 !important;
}

label {
    color: $neutral-level-3 !important;
}

input {
    color: $neutral-level-4 !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    height: 40px !important;
}

$color_1: #444444;
$color_2: #a5c5fe;
$background-color_1: #f6f9ff;

@keyframes dropdown-animate {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
    0% {
        opacity: 0;
    }
}
.dropdown-menu {
    border-radius: 4px;
    padding: 10px 0;
    animation-name: dropdown-animate;
    animation-duration: 0.2s;
    animation-fill-mode: both;
    border: 0;
    box-shadow: 0 5px 30px 0 rgba(82, 63, 105, 0.2);
    .dropdown-header {
        text-align: center;
        font-size: 15px;
        padding: 10px 25px;
    }
    .dropdown-footer {
        text-align: center;
        font-size: 15px;
        padding: 10px 25px;
        a {
            color: $color_1;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }
    .dropdown-divider {
        color: $neutral-level-6;
        margin: 0;
    }
    .dropdown-item {
        font-size: 14px;
        padding: 10px 15px;
        transition: 0.3s;
        i {
            margin-right: 10px;
            font-size: 18px;
            line-height: 0;
        }
        &:hover {
            background-color: $background-color_1;
        }
    }
}
@media (min-width: 768px) {
    .dropdown-menu-arrow {
        &::before {
            content: "";
            width: 13px;
            height: 13px;
            background: #fff;
            position: absolute;
            top: -7px;
            right: 20px;
            transform: rotate(45deg);
            border-top: 1px solid #eaedf1;
            border-left: 1px solid #eaedf1;
        }
    }
}
