@use "./common_variables" as *;
@use "./colors" as *;

dt {
    text-align: left !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: $neutral-level-3 !important;
}

dd {
    text-align: left !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5%;
    color: $neutral-level-4 !important;
}

.timeline {
    position: relative;
    margin: 0 0 30px 0;
    padding: 0;
    list-style: none;
}

.timeline::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2px;
    background: $neutral-level-6 !important;
    left: 31px;
    margin: 0;
    border-radius: 2px;
}

.timeline>li {
    position: relative;
    margin-right: 10px;
    margin-bottom: 15px;
}

.timeline>li:before,
.timeline>li:after {
    content: " ";
    display: table;
}

.timeline>li:after {
    clear: both;
}

.timeline>li>.timeline-item {
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .1);
    box-shadow: 0 1px 1px rgba(0, 0, 0, .1);
    border-radius: 3px;
    margin-top: 0;
    background: #fff;
    color: #444;
    margin-left: 60px;
    margin-right: 15px;
    padding: 0;
    position: relative;
}

.timeline>li>.timeline-item>.time {
    color: #999;
    float: right;
    padding: 10px;
    font-size: 12px;
}

.timeline>li>.timeline-item>.timeline-header {
    margin: 0;
    color: #555;
    border-bottom: 1px solid #f4f4f4;
    padding: 10px;
    font-size: 16px;
    line-height: 1.1;
}

.timeline-header {
    border-radius: 8px;
    justify-content: space-between;
    padding: 10px, 18px, 10px, 18px;
}

.timeline>li>.timeline-item>.timeline-header>a {
    font-weight: 600;
}

.timeline>li>.timeline-item>.timeline-body,
.timeline>li>.timeline-item>.timeline-footer {
    padding: 10px;
}

.timeline>li>.fa,
.timeline>li>.ion {
    width: 30px;
    height: 30px;
    font-size: 15px;
    line-height: 30px;
    position: absolute;
    color: #666;
    background: #d2d6de;
    border-radius: 50%;
    text-align: center;
    left: 18px;
    top: 0;
}

.time-label {
    padding: 8px, 16px, 8px, 16px;
    display: inline-block;
    background-color: #EDF5FF;
    border-radius: 8px;
    letter-spacing: 2%;
    padding: 10px;
}

.timeline-inverse>li>.timeline-item {
    background: #f0f0f0;
    border: 1px solid #ddd;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.timeline-inverse>li>.timeline-item>.timeline-header {
    border-bottom-color: #ddd;
}

.timeline-sub-header {
    padding: 0px, 16px, 0px, 16px;
}

.timeline-icon {
    position: absolute !important;
    padding-left: 10px;
}

.time {
    justify-content: end;
    color: $neutral-level-4 !important;
}

.light-hide {
    display: none;
}

.box-header.with-border {
    border-bottom: 1px solid #f4f4f4;
}

.collapsed-box .box-header.with-border {
    border-bottom: none;
}

.bg-gray {
    color: #000;
    background-color: #d2d6de !important;
}

.bg-gray-light {
    background-color: #F2F5F8;
}

.bg-blue {
    background-color: #EDF5FF;
}

.bi-dot {
    color: $neutral-level-6 !important;
}

@media(min-width:768px) {
    .dl-horizontal dt {
        float: left;
        width: 160px;
        overflow: hidden;
        clear: left;
        text-align: right;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .dl-horizontal dd {
        margin-left: 180px;
    }
}