@use "../styles/colors" as *;
@use "../styles/common_variables" as *;
@use "../styles/variables" as *;

th {
    background-color: #f2f5f8 !important;
    color: $neutral-level-3 !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.5%;
    padding-left: 16px !important;
}

td {
    color: $neutral-level-3 !important;
    background-color: #ffffff !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5%;
    padding-left: 16px !important;
    border: 1px solid #dde4ee;
    height: 56px !important;
    vertical-align: middle !important;
}

table {
    border-radius: 8px !important;
}

div.dataTables_wrapper div.dataTables_info,
div.dataTables_length select {
    color: $neutral-level-4 !important;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
}

div.dataTables_wrapper div.dataTables_filter input {
    border: 1px solid #dde4ee !important;
    padding: 12px;
    border-radius: 8px;
    gap: 8px;
    color: $neutral-level-4 !important;
}

div.dataTables_wrapper div.dataTables_filter::placeholder {
    color: #5c7089 !important;
}

.grid-button {
    position: inherit;
}

.pagination {
    --bs-pagination-active-bg: var(--color-secondary);
    --bs-pagination-active-border-color: var(#5c7089) !important;
    --bs-pagination-active-color: #21344e !important;
    --bs-pagination-color: #5c7089 !important;
    --bs-pagination-border-width: none !important;
    --bs-pagination-disabled-bg: none;
    --bs-pagination-hover-bg: var(--color-secondary);
    --bs-pagination-hover-color: #21344e !important;
    --bs-pagination-focus-color: #21344e !important;
    --bs-pagination-focus-bg: var(--color-secondary);
}

.paginate_button {
    border-radius: 8px;
}

.empty-stock {
    background-color: var(--color-secondary) !important;

    .btn-grid {
        background-color: var(--color-secondary) !important;
    }
}

div.dataTables_processing > div:last-child > div {
    background: var(--color-primary) !important;
}
