@use "../styles/colors" as *;
@use "../styles/common_variables" as *;
$background-color_1: #fff;
.footer-margin {
    margin-bottom: 60px;
}
.full-height {
    height: 100vh !important;
}

.white-bg {
    background-color: #ffffff;
}

.pointer-section {
    cursor: pointer;
}

.text-center {
    text-align: center;
}

.flex-direction-column {
    flex-direction: column;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
    text-align: center;
}

.justify-content-space-around {
    display: flex;
    justify-content: space-around;
}

.text-end {
    text-align: end;
}

.text-start {
    text-align: start;
}

.card-content {
    padding: 24px;
    background-color: #f2f5f8;
    width: 100%;
    border-radius: 16px;
}

.card-content-white {
    padding: 16px;
    background-color: #fdfeff;
    border-radius: 8px;
    width: 100%;
}

.display-flex {
    display: flex;
}

.position-absolute {
    position: absolute;
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    box-sizing: border-box;
    width: 100%;
}

.footer-register {
    border-top: 1px solid #dde4ee;
    padding: 16px 24px;
}

.footer-background {
    background-color: #ffffff !important;
}

.cards-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    gap: 8px;
}

@media (max-width: 768px) {
    .cards-wrapper {
        display: inline;
    }
}

.footer-position {
    position: fixed;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
}

.footer-separator {
    border-top: 1px solid $neutral-level-6;
}

.section-step {
    position: relative;
    width: 100%;
    padding-bottom: 100px;
    box-sizing: border-box;
    overflow-y: hidden;
}

@media (max-width: 768px) {
    .footer {
        display: flex;
        flex-direction: column-reverse;
        align-items: center !important;
    }
}

.bg-border {
    border-bottom: 1px solid $neutral-level-6;
}

// ==================================  sidebar  ===========================

.sidebar {
    position: fixed;
    top: 60px;
    left: 0;
    bottom: 0;
    width: 300px;
    z-index: 996;
    transition: all 0.3s;
    padding: 20px;
    overflow-y: auto;
    border-right: 1px solid $neutral-level-6;
    background-color: $background-color_1;
}
.sidebar-nav {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
        padding: 0;
        margin: 0;
        list-style: none;
    }
    .nav-item {
        margin-bottom: 5px;
    }
    .nav-heading {
        font-size: 11px;
        text-transform: uppercase;
        color: $secondary-color;
        font-weight: 600;
        margin: 10px 0 5px 15px;
    }
    .nav-link {
        display: flex;
        align-items: center;
        font-size: 15px;
        font-weight: 600;
        color: $neutral-level-2;
        transition: 0.3;
        background: $secondary-color;
        padding: 10px 15px;
        border-radius: 8px;
        i {
            font-size: 16px;
            margin-right: 10px;
            color: $primary-color;
        }
        &:hover {
            color: $neutral-level-2;
            background-color: $secondary-color;
            i {
                color: $neutral-level-2;
            }
        }
        .bi-chevron-down {
            color: $primary-color;
            margin-right: 0;
            transition: transform 0.2s ease-in-out;
        }
        &:not(.collapsed) {
            .bi-chevron-down {
                transform: rotate(180deg);
            }
        }
    }
    .nav-link.collapsed {
        color: $neutral-level-2;
        background: #fff;
        i {
            color: $primary-color;
        }
        &:hover {
            color: $neutral-level-2;
            background-color: $secondary-color;
            i {
                color: $neutral-level-2;
            }
        }
    }
    .nav-content {
        padding: 5px 0 0 0;
        margin: 0;
        list-style: none;
        a {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 600;
            color: $neutral-level-2;
            transition: 0.3;
            padding: 10px 0 10px 40px;
            transition: 0.3s;
            i {
                font-size: 6px;
                margin-right: 8px;
                line-height: 0;
                border-radius: 50%;
            }
            &:hover {
                color: $neutral-level-2;
                background-color: $secondary-color;
                border-radius: 8px;
            }
        }
        a.active {
            color: $neutral-level-2;
            i {
                background-color: $primary-color !important;
            }
        }
    }
}

@media (max-width: 755px) {
    .sidebar {
        left: -300px;
    }
    .toggle-sidebar {
        .sidebar {
            left: 0;
        }
    }
}
@media (min-width: 755px) {
    .sidebar-margin {
        margin-left: 300px;
    }

    .header-margin {
        margin-top: 60px;
    }
    .footer-form {
        margin-left: 300px;
    }
    // .footer-form {
    //     margin-left: 300px;
    // }
    // .toggle-sidebar {
    //     .sidebar-margin {
    //         margin-left: 0;
    //     }
    //     // .footer-form {
    //     //     margin-left: 0;
    //     // }
    //     .sidebar {
    //         left: -300px;
    //     }
    // }
}

// ===================== header =======================================
