:root {
    --color-primary: #ffa125;
    --color-secondary: #fff5e9;
    --color-tertiary: #dd8613;
    --color-button-text: #001633;
    --image-url: url("../../../../assets/images/img-no-content.svg");
    --image-register: url("../../../../assets/images/background-forms.svg");
    --icon-url: url("../../../../assets/icons/logo-default.svg");
    --bs-primary: #ffa125;
}
