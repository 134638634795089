@use "../styles/common_variables" as *;
@use "../styles/colors" as *;


.ng-select .ng-select-container .ng-value-container .ng-placeholder {
    color: $neutral-level-4 !important;
}

.ng-select.ng-select-single .ng-select-container {
    height: 58px !important;
    padding: 10px 20px !important;
    cursor: pointer !important;
}

.ng-select.ng-select-single.ng-select-container {
    height: 58px !important;
    padding: 10px 20px !important;
    gap: 8px !important;
    border: 1px solid $neutral-level-6 !important;
    border-radius: 8px !important;
    background-color: $neutral-level-8 !important;
    font-weight: 300 !important;
    width: 100% !important;
    color: $neutral-level-4 !important;
}

.ng-select-container::after {
    height: 58px !important;
    padding: 10px 20px !important;
    border: 1px solid $primary-color !important;
    border-radius: 8px !important;
    outline: none !important;
    box-shadow: none !important;
    background-color: $neutral-level-8 !important;
}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
    border-color: $primary-color !important;
    color: $neutral-level-4 !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label .ng-value {
    color: $neutral-level-4 !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    color: $neutral-level-4 !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    color: $neutral-level-3 !important;
    padding: 12px 30px !important;
}

.ng-select .ng-select-container {
    border: 1px solid $neutral-level-6 !important;
    border-radius: 8px !important;
    color: $neutral-level-4 !important;
}

.ng-select .ng-arrow-wrapper {
    color: red !important
}

.ng-select .ng-select-container .ng-value-container .ng-input>input {
    padding: 12px 30px !important;
}